import styled from 'styled-components/macro';
import {blue, pink, lightGray} from '../modules/colors';
import media from '../modules/media';
import '../../node_modules/hamburgers/dist/hamburgers.min.css';

export const Wrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const Title = styled.div`

  a {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

export const Name = styled.span`
  margin-left: 20px;
  font-size: 30px;
  color: ${blue};
  font-weight: bold;

  ${media.tablet`font-size: 24px;`};
  ${media.phone`font-size: 20px;`};
`;

export const Menu = styled.ul`
  display: flex;
  list-style-type: none;
  margin-left: 40px;

  ${media.desktop`margin-left: 0px;`}

  ${media.tablet`
    ${props => props.hide && 'display: none;'}
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: ${lightGray};
    z-index: 1;
  `}
`;

export const MenuItem = styled.li`
  font-size: 16px;
  padding: 0 20px;

  ${media.desktop`padding: 0 7px;`}
  ${media.tablet`
    text-align: center;

    a {
      padding: 20px;
      display: block;
    }
  `};

  a {
    color: ${pink};

    &:hover {
      color: ${blue};
    }
  }
`;

export const Hamburger = styled.button`
  display: none !important;
  margin-left: auto !important;
  z-index: 2 !important;

  ${media.tablet`display: inline-block !important;`};
`;
