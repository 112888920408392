import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {StaticQuery, graphql, navigate} from 'gatsby';
import styled, {createGlobalStyle} from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header';
import Footer from './Footer';
import request from '../modules/request';
import UserContext from '../modules/UserContext';
import LoadingContext from '../modules/LoadingContext';
import {lightGray, superLightGray, textColor, blue} from '../modules/colors';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Oxygen', sans-serif;
    background: ${superLightGray};
    margin: 0;
    padding: 0;
    color: ${textColor};
  }

  a, button {
    text-decoration: none;
    transition: color 0.5s ease 0s, filter 0.5s ease 0s;
  }

  .__react_component_tooltip {
    max-width: 250px;
  }

  .Toastify__toast.Toastify__toast--success {
    background-color: ${blue};
  }
`;

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 16px 48px ${lightGray};
  background: white;
`;

const Layout = ({children}) => {
  // defaultUserInfo exist so that some JSX is not breaking by undefined
  const defaultUserInfo = {
    cards: [],
    spreadsheets: [],
    requests: []
  };

  const [userInfo, setUserInfo] = useState(defaultUserInfo);
  const [loading, setLoading] = useState(true);

  // rebuild tooltips (force tooltips to add event listeners to elements with data-tip attr after page change)
  useEffect(() => {
    if (!loading) {
      setTimeout(() => ReactTooltip.rebuild(), 100);
    }
  }, [children, loading]);

  const fetchData = async () => {
    try {
      const response = await request('user/info');
      const responseJSON = await response.json();
      setLoading(false);

      if (typeof window !== 'undefined') {
        if (responseJSON.email) {
          setUserInfo(responseJSON);
          // if user has an incomplete subscription, show a toast permanently
          if (responseJSON.subscriptionStatus === 'incomplete') {
            toast.error('Please complete the payment to finish the subscription upgrade.', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: false
            });
          }
        } else {
          // we are not logged in, so reset the saved user
          setUserInfo(defaultUserInfo);

          // if the user is on a must-be-loggedin path, redirect to home
          if (window.location.pathname === '/dashboard/') {
            navigate('/');
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              name
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {name: 'description', content: 'Create a GraphQL API from your Google Spreadsheets'},
              {name: 'keywords', content: 'GraphQL, Google, Google Drive, Google Spreadsheet, API'}
            ]}>
            <html lang="en" />
            <link href="https://fonts.googleapis.com/css?family=Oxygen" rel="stylesheet" />
          </Helmet>
          <GlobalStyle />
          <UserContext.Provider value={{...userInfo}}>
            <LoadingContext.Provider value={loading}>
              <Wrapper>
                <Header siteTitle={data.site.siteMetadata.name} />
                {children}
                <Footer />
              </Wrapper>
              <ReactTooltip effect="solid" multiline />
            </LoadingContext.Provider>
          </UserContext.Provider>
          <ToastContainer />
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
