const blue = 'rgb(0, 163, 188)';
const white = 'rgb(255, 255, 255)';
const superLightGray = 'rgb(246, 248, 250)';
const lightGray = 'rgb(226, 232, 237)';
const boldTextColor = 'rgb(31, 43, 53)';
const textColor = 'rgb(111, 131, 148)';
const pink = 'rgb(239, 88, 130)';
const gray = 'rgb(118, 134, 150)';

export {
  boldTextColor,
  blue,
  lightGray,
  gray,
  pink,
  superLightGray,
  textColor,
  white
};
