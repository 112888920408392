const BEurl = process.env.BACKEND;

const request = (path, body = {}, credentials = true) => fetch(`${BEurl}/${path}`, {
  method: 'POST',
  ...(credentials ? {credentials: 'include'} : {}),
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
});

export default request;
