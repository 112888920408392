import {createContext} from 'react';

const defaultValue = {
  cards: [],
  Spreadsheets: [],
  requests: []
};

const UserContext = createContext(defaultValue);

export default UserContext;
