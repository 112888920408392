import styled from 'styled-components/macro';
import {boldTextColor} from '../modules/colors';
import media from '../modules/media';

export const Wrapper = styled.div`
  background: ${boldTextColor};
`;

export const Row1 = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 20px 20px 30px 20px;
  width: 100%;
  box-sizing: border-box;

  ${media.phone`flex-flow: wrap;`};
`;

export const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  ${media.phone`flex-flow: wrap;`};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  width: 140px;

  ${media.phone`width: 100%;`};

  > a {
    color: white;
    padding: 8px 0;
    font-size: 14px;
  }
`;

export const Header = styled.h4``;
