import React from 'react';
import {Link} from 'gatsby';
import {Wrapper, Row1, Row2, Column, Header} from './Footer.styles';

const Footer = () => (
  <Wrapper id="footer">
    <Row1>
      <Column>
        <Header>Resources</Header>
        <Link to="/documentation">Documentation</Link>
        <Link to="/create">Get Started</Link>
      </Column>
      <Column>
        <Header>Support</Header>
        <Link to="/contact">Contact Us</Link>
        <Link to="/#faq">FAQ</Link>
        <a href="https://stats.uptimerobot.com/W8n0NCY8q" target="blank">
          Status
        </a>
        <a href="https://twitter.com/graphQLsheet" target="blank">
          Twitter
        </a>
      </Column>
      <Column>
        <Header>Company</Header>
        <Link to="/terms">Terms &amp; Conditions</Link>
      </Column>
    </Row1>
    <Row2>
      <span>
        GraphQLsheet.com &nbsp; © &nbsp;
        {new Date().getFullYear()}
      </span>
      <span>All rights reserved</span>
    </Row2>
  </Wrapper>
);

export default Footer;
